import Link from 'next/link';

const AgentLink = ({ roomName }: any) => {
  return (
    <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
      <Link style={{ textDecoration: 'none' }} href={`/agent/`}>
        Login as host
      </Link>
    </div>
  );
};

export default AgentLink;
