import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import React, { ReactElement, useState } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '../lib/client-utils';
import styles from '../styles/Home.module.css';
import Footer from '../components/Footer';
import AgentLink from '@/components/AgentLink';

interface TabsProps {
  children: ReactElement[];
  selectedIndex?: number;
  onTabSelected?: (index: number) => void;
}

export const getServerSideProps: GetServerSideProps<{ tabIndex: number }> = async ({
  query,
  res,
}) => {
  res.setHeader('Cache-Control', 'public, max-age=7200');
  const tabIndex = query.tab === 'custom' ? 1 : 0;
  return { props: { tabIndex } };
};

const Home = ({ tabIndex }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  function onTabSelected(index: number) {
    const tab = index === 1 ? 'custom' : 'demo';
    router.push({ query: { tab } });
  }
  return (
    <>
      <main className="main" data-lk-theme="default">
        <AgentLink />
        <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
          <div className="header">
            <img
              src="https://framerusercontent.com/images/X01vDpfkKKl1sNVSXCe6XyGulOs.svg"
              alt="Popin"
              style={{ width: '360px' }}
            />
            <h2>Popin meeting app</h2>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Home;
