const Footer: React.FC = () => {
  return (
    <footer data-lk-theme="default">
      <span>Powered by </span>
      <a target="_blank" href="https://popin.to">
        <img alt="Popin" src="/images/logo-dark.svg" />
      </a>
    </footer>
  );
};

export default Footer;
